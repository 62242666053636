// Library Imports
import React, { useState, useEffect } from 'react';

// Local Imports
import UnitsUI from './UnitsUI';
import {
  Action,
  ForLease,
  ForSale,
  Status,
} from '../../../../../../components/Tables/utils';
import {
  unitsColumnData,
  unitsColumnExtensionsData,
} from '../../../../../../components/Tables/dummyData';
import ApiController from '../../../../../../utils/network/api';
import DeleteUnit from '../DeleteUnit/DeleteUnit';
import AddEditUnitUI from '../AddEditUnit/AddEditUnitUI';
import { toUpperCase } from '../../../../../../utils/validations/localValidations';
import UnitDetails from '../UnitDetails/UnitDetails';
import ImportUnitsModal from './ImportData';
import { getCurrentDate } from '../../../../../../utils/utils';
import { UNITBASEURLFORWEB } from '../../../../../../utils/network/constants';
import { useSearchParams } from 'react-router-dom';

const UnitsTab = ({
  openAddEditUnit,
  toggleAddEditUnitModal,
  closeAddEditUnit,
  openCSVModal,
  closeImportDataModal,
  isSelectedCampus,
  setIsSelectedCampus,
}) => {
  //************************* Unit Main Screen Start *******************************//

  //***** States *****//
  const [ColumnSetting1] = useState(['action']);
  const [ColumnSetting2] = useState(['forSale']);
  const [ColumnSetting3] = useState(['forLease']);
  const [ColumnSetting4] = useState(['status']);
  const [selectionIds, setSelectionIds] = useState([]);
  const [copyUnitsData, setCopyUnitsData] = useState({});
  const [units, setunits] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [unitsListLoading, setunitsLoading] = useState(false);
  const [storageSelectAll, setStorageSelectAll] = useState(false);
  const [storageList] = React.useState([
    {
      id: 1,
      name: 'RV and CARS',
    },
    {
      id: 2,
      name: 'PERSONAL ITEMS',
    },
    {
      id: 3,
      name: 'BOATS and PWC',
    },
    {
      id: 4,
      name: 'TOOLS and MATERIALS',
    },
  ]);

  const [leasePrice, setLeasePrice] = useState({
    sft12Month: null,
    sft6Month: null,
    sft1Month: null,
  });

  const [networkError, setNetworkError] = useState(false);
  const date = getCurrentDate();

  useEffect(() => {
    getUnitsListing();
    getCampusesListing();
    // eslint-disable-next-line
  }, []);

  //***** Methods *****//
  const dataProviders = [
    {
      columnName: ColumnSetting1,
      func: (restProps) => Action(restProps, onActionClick),
    },
    {
      columnName: ColumnSetting2,
      func: ForSale,
    },
    {
      columnName: ColumnSetting3,
      func: ForLease,
    },
    {
      columnName: ColumnSetting4,
      func: Status,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get('page');
  const pageSize = searchParams.get('pageSize');

  // Action Click From Table
  const onActionClick = (type, unitData) => {
    if (type === 'edit') {
      setEditDataLoading(true);
      setEditUnitData(unitData);
      ApiController.unitDetailsCall(unitData.id, (response) => {
        if (response.success) {
          setImages(response?.data?.unit_image_url);
          setUnitDetails({ rawData: response?.data });
          const unit = response?.data;
          const campusLeaseValues = filters?.Campus?.filter(
            (camp) => camp?.title === unit?.facility_name
          );
          setLeasePrice((prev) => ({
            ...prev,
            sft12Month: campusLeaseValues[0]?.leasePrice12Month,
            sft6Month: campusLeaseValues[0]?.leasePrice6Month,
            sft1Month: campusLeaseValues[0]?.leasePriceMonthToMonth,
          }));
          const pageData =
            response?.data?.unit_page !== null ? response?.data?.unit_page : {};

          const amenityIds = unit?.unit_amenities?.map(
            (amenity) => amenity?.amenity_id
          );
          const facilityIds = unit?.facility_amenities?.map(
            (amenity) => amenity?.amenity_id
          );
          const finalAmmenityIds = [
            ...new Set(amenityIds?.concat(facilityIds)),
          ];

          unit?.storage_feature && unit?.storage_feature[0] === 'ALL TYPES'
            ? setStorageSelectAll(true)
            : setStorageSelectAll(false);

          const selectedAmenities = unit?.facility_amenities?.map((item) => {
            return item?.amenity__name;
          });

          setNewUnit({
            title: 'Edit Unit',
            id: unitData?.id,
            description: unitData?.description,
            url: UNITBASEURLFORWEB,
            customURI: pageData?.end_url ? pageData?.end_url : 'enter-uri',
            publishDate: pageData?.unit_publish_date
              ? pageData?.unit_publish_date?.split('T')[0]
              : date,
            unitPassword: pageData?.password,
            facilityId: unitData?.facilityId,
            facilityName: unitData?.facility,
            unitNum: unitData?.unitNumber,
            length: unitData?.length,
            width: unitData?.width,
            sqFeet: unitData?.sqFt,
            maintenanceFee: unitData?.maintenanceFee,
            availableForSale: unitData?.forSale,
            availableForLease: unitData?.forLease,
            leasePrice: unitData?.leasePrice,
            buyPrice: unitData?.buyPrice,
            status: unitData?.status,
            newUnitType: unit?.unit_type,
            unitTitle: unit?.unit_page?.title,
            unitId: unit?.unit_page?.id,
            unitDescription: unit?.unit_page?.description,
            unitPassword: unit?.unit_page?.password,
            unitVisibility: unit?.unit_page?.visibility,
            unitDraft: unit?.unit_page?.publish ? false : true,
            storage:
              unit?.storage_feature !== null
                ? unit?.storage_feature[0] === 'ALL TYPES'
                  ? storageList
                  : storageList?.filter((item) =>
                      unit?.storage_feature?.includes(item?.name)
                    )
                : null,
            amenities: finalAmmenityIds,
            ...(typeof unit?.unit_owner === 'object' && {
              owner: unit?.unit_owner,
              ownerId: unit?.unit_owner?.owner_id,
              ownerName: `${unit?.unit_owner?.first_name} ${unit?.unit_owner?.last_name}`,
              ownerEmail: unit?.unit_owner?.email,
              ownerPhone:
                unit?.unit_owner?.phone_no !== null
                  ? unit?.unit_owner?.phone_no
                  : 'No phone number linked with selected user',
              purchaseDate: unit?.unit_owner?.purchase_date,
              sellDate: unit?.unit_owner?.sell_date,
            }),
            ...(typeof unit?.unit_lessee === 'object' && {
              lessee: unit?.unit_lessee,
              lesseeId: unit?.unit_lessee?.lessee_id,
              lesseeName: `${unit?.unit_lessee?.first_name} ${unit?.unit_lessee?.last_name}`,
              lesseeEmail: unit?.unit_lessee?.email,
              lesseePhone:
                unit?.unit_lessee?.phone_no !== null
                  ? unit?.unit_lessee?.phone_no
                  : 'No phone number linked with selected user',
              startDate: unit?.unit_lessee?.start_date,
              endDate: unit?.unit_lessee?.end_date,
            }),
            selectedAmenities: selectedAmenities,
            seoUnitTitle: unit?.unit_page?.unit_seo_title || '',
            seoMetaDescription: unit?.unit_page?.unit_seo_description || '',
            seoKeywords: unit?.unit_page?.unit_seo_keywords?.join(',') || '',
          });

          setCopyUnitsData({
            title: 'Edit Unit',
            id: unitData?.id,
            description: unitData?.description,
            url: UNITBASEURLFORWEB,
            customURI: pageData?.end_url ? pageData?.end_url : 'enter-uri',
            publishDate: pageData?.unit_publish_date
              ? pageData?.unit_publish_date?.split('T')[0]
              : date,
            unitPassword: pageData?.password,
            facilityId: unitData?.facilityId,
            facilityName: unitData?.facility,
            unitNum: unitData?.unitNumber,
            length: unitData?.length,
            width: unitData?.width,
            sqFeet: unitData?.sqFt,
            maintenanceFee: unitData?.maintenanceFee,
            availableForSale: unitData?.forSale,
            availableForLease: unitData?.forLease,
            leasePrice: unitData?.leasePrice,
            buyPrice: unitData?.buyPrice,
            status: unitData?.status,
            newUnitType: unit?.unit_type,
            unitTitle: unit?.unit_page?.title,
            unitId: unit?.unit_page?.id,
            unitDescription: unit?.unit_page?.description,
            unitPassword: unit?.unit_page?.password,
            unitVisibility: unit?.unit_page?.visibility,
            unitDraft: unit?.unit_page?.publish ? false : true,
            storage:
              unit?.storage_feature !== null
                ? unit?.storage_feature[0] === 'ALL TYPES'
                  ? storageList
                  : storageList?.filter((item) =>
                      unit?.storage_feature?.includes(item?.name)
                    )
                : null,
            amenities: JSON.parse(JSON.stringify(finalAmmenityIds)),
            ...(typeof unit?.unit_owner === 'object' && {
              owner: unit?.unit_owner,
              ownerId: unit?.unit_owner?.owner_id,
              ownerName: `${unit?.unit_owner?.first_name} ${unit?.unit_owner?.last_name}`,
              ownerEmail: unit?.unit_owner?.email,
              ownerPhone:
                unit?.unit_owner?.phone_no !== null
                  ? unit?.unit_owner?.phone_no
                  : 'No phone number linked with selected user',
              purchaseDate: unit?.unit_owner?.purchase_date,
              sellDate: unit?.unit_owner?.sell_date,
            }),
            ...(typeof unit?.unit_lessee === 'object' && {
              lessee: unit?.unit_lessee,
              lesseeId: unit?.unit_lessee?.lessee_id,
              lesseeName: `${unit?.unit_lessee?.first_name} ${unit?.unit_lessee?.last_name}`,
              lesseeEmail: unit?.unit_lessee?.email,
              lesseePhone:
                unit?.unit_lessee?.phone_no !== null
                  ? unit?.unit_lessee?.phone_no
                  : 'No phone number linked with selected user',
              startDate: unit?.unit_lessee?.start_date,
              endDate: unit?.unit_lessee?.end_date,
            }),
            selectedAmenities: selectedAmenities,
            seoUnitTitle: unit?.unit_page?.unit_seo_title || '',
            seoMetaDescription: unit?.unit_page?.unit_seo_description || '',
            seoKeywords: unit?.unit_page?.unit_seo_keywords?.join(',') || '',
          });

          setEditDataLoading(false);
        } else {
          setEditDataLoading(false);
        }
      });
      toggleAddEditUnitModal(true);
    } else {
      setDeleteUnit(unitData);
      toggledeleteUnitModal(true);
    }
  };

  const getCampusesListing = (name, zones, status) => {
    ApiController.fetchCampusesCall(name, zones, status, (response) => {
      if (response?.success) {
        // sort response array
        const sortArray = response.data.sort(function (a, b) {
          return a.id - b.id || a.name.localeCompare(b.name);
        });
        // Set Facilities List for Modal
        setFacilitiesList(response?.data);
        let data = sortArray.map((item) => {
          let obj = {
            id: item.id,
            title: item.name,
            value: false,
            status: item.is_active,
            maintenanceFee: item.maintenance_fee,
            leasePriceMonthToMonth: item?.price_per_sft_1_month || null,
            leasePrice6Month: item?.price_per_sft_6_month || null,
            leasePrice12Month: item?.price_per_sft_12_month || null,
          };
          return obj;
        });
        let status = filters.Status.slice();
        let unitTypes = filters.UnitTypes.slice();
        let availability = filters.Availability.slice();
        let campus = data;
        setFilters(() => {
          const newObj = {
            Campus: campus,
            Status: status,
            UnitTypes: unitTypes,
            Availability: availability,
          };
          return newObj;
        });
      }
    });
  };

  // Fetching And Setting Unit Listing Data
  const filterString = (campus, status, unitType, availability) => {
    let campusStr, statusStr, unitTypeStr, availabilityStr;

    // making string for campus filter
    if (campus) {
      // eslint-disable-next-line
      campus.map((item) => {
        if (item.value) {
          campusStr = campusStr ? campusStr + ',' + item.title : item.title;
        }
      });
    }

    // making string for unit types filter
    if (unitType) {
      // eslint-disable-next-line
      unitType.map((item) => {
        if (item.value) {
          unitTypeStr = unitTypeStr
            ? unitTypeStr + ',' + item.title
            : item.title;
        }
      });
    }

    // making string for availibility filter
    if (availability) {
      // eslint-disable-next-line
      availability.map((item) => {
        if (item.value) {
          availabilityStr = item.title.toLowerCase();
        }
      });
    }

    // making string for status filter
    if (status) {
      // eslint-disable-next-line
      status.map((item) => {
        if (item.value) {
          statusStr = item.title.toLowerCase();
        }
      });
    }
    let obj = {
      campus: campusStr,
      status: statusStr,
      unitType: unitTypeStr,
      availability: availabilityStr,
    };
    return obj;
  };

  const getUnitsListing = (
    name,
    campus,
    status,
    buyPrice,
    leasePrice,
    widthFilter,
    lengthFilter,
    unitType,
    availability
  ) => {
    let filters = filterString(campus, status, unitType);

    setunitsLoading(true);

    ApiController.fetchUnitsCall(
      name,
      filters.campus,
      filters.status,
      filters?.unitType,
      availability,
      buyPrice,
      leasePrice,
      widthFilter,
      lengthFilter,
      pageSize,
      pageNumber,
      (response) => {
        if (response?.success) {
          // sort response array
          const sortArray = response.data.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
          });
          // create object to render table
          let data = sortArray.map((item) => {
            let obj = {
              id: item.id,
              facility: item.facility_name,
              facilityId: item.facility,
              maintenanceFee: item.maintenance_fee,
              owner:
                typeof item.unit_owner_name === 'object'
                  ? `${item?.unit_owner_name?.first_name || 'LuxeLocker'} 
                  ${item?.unit_owner_name?.last_name || ''}`
                  : item.unit_owner_name || 'LuxeLocker',
              ownerObject:
                typeof item.unit_owner_name === 'object' &&
                item.unit_owner_name,
              lessee:
                typeof item.unit_lessee_name === 'object'
                  ? `${item?.unit_lessee_name?.first_name || '-'} ${
                      item?.unit_lessee_name?.last_name || '-'
                    }`
                  : item.unit_lessee_name || '-',
              lesseeObject:
                typeof item.unit_lessee_name === 'object' &&
                item.unit_lessee_name,
              unitNumber: item.unit_number,
              length: item.length,
              width: item.width,
              sqFt: (item.length * item.width).toFixed(2),
              buyPrice: item.buy_price,
              leasePrice: item.lease_price,
              leasePriceMonthToMonth: item?.lease_price_1_month || '-',
              leasePrice6Month: item?.lease_price_6_month || '-',
              leasePrice12Month: item?.lease_price_12_month || '-',
              forSale: item.is_available_for_sale,
              forLease: item.is_available_for_lease,
              status: item.is_active,
              description: item?.unit_description,
            };
            return obj;
          });
          setMetaData(response?.meta);
          setunits(data);
          setunitsLoading(false);
        } else {
          setunitsLoading(false);
          setNetworkError(true);
        }
      }
    );
  };
  // Again Call For Listing
  const reCallListing = () => {
    setNetworkError(false);

    getUnitsListing(
      searchText,
      filters?.Campus,
      filters?.Status,
      buyValue,
      leaseValue,
      widthFilter,
      lengthFilter,
      filters?.UnitTypes,
      filters?.Availability
    );
  };

  //************************* Unit Main Screen End *******************************//

  //************************* Search Bar Start *******************************//

  //***** States *****//
  const [searchText, setSearchText] = useState('');

  //***** Methods *****//

  // Search User Method
  const onChangeSearch = (e) => {
    if (e.target.value !== '') {
      setSearchText(e.target.value);
    } else {
      setSearchParams({});
      setSearchText('');
      getUnitsListing();
    }
  };

  const onUnitsSearch = () => {
    setSearchParams({});
    if (searchText) {
      getUnitsListing(searchText);
    }
  };

  // onSearch Clear
  const onClear = () => {
    setSearchParams({});
    setSearchText('');
    getUnitsListing();
  };
  //************************* Search Bar End *******************************//

  //************************* Filter Start *******************************//
  //***** States *****//
  // Filter Modal States
  const [listOpen, setListOpen] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [filters, setFilters] = useState({
    Campus: [],
    UnitTypes: [
      {
        title: 'All',
        value: false,
      },
      {
        title: 'Standard',
        value: false,
      },
      {
        title: 'Luxe',
        value: false,
      },
      {
        title: 'Super Luxe',
        value: false,
      },
    ],
    Status: [
      {
        title: 'Active',
        value: false,
      },
      {
        title: 'Inactive',
        value: false,
      },
    ],
    Availability: [
      {
        title: 'For Sale',
        value: false,
      },
      {
        title: 'For Lease',
        value: false,
      },
    ],
  });

  // Range Buy Price
  const [buyValue, setBuyValue] = useState([0, 0]);
  const [leaseValue, setLeaseValue] = useState([0, 0]);
  const [widthFilter, setWidthFilter] = useState([0, 0]);
  const [lengthFilter, setLengthFilter] = useState([0, 0]);

  const handlePriceChanges = (event, newValue) => {
    const { name, value } = event.target;
    if (name === 'buyPrice') {
      setBuyValue(newValue);
    }

    if (name === 'leasePrice') {
      setLeaseValue(newValue);
    }

    if (name === 'width') {
      setWidthFilter(newValue);
    }

    if (name === 'length') {
      setLengthFilter(newValue);
    }
  };

  //***** Methods *****//

  const listingCallWithPriceRange = (from) => {
    if (from === 'buyPrice') {
      getUnitsListing(
        '',
        filters.Campus,
        filters.Status,
        [0, 0],
        leaseValue,
        widthFilter,
        lengthFilter,
        filters.UnitTypes,
        filters.Availability
      );
    } else if (from === 'leasePrice') {
      getUnitsListing(
        '',
        filters.Campus,
        filters.Status,
        buyValue,
        [0, 0],
        widthFilter,
        lengthFilter,
        filters.UnitTypes,
        filters.Availability
      );
    } else if (from === 'width') {
      getUnitsListing(
        '',
        filters.Campus,
        filters.Status,
        buyValue,
        leaseValue,
        [0, 0],
        lengthFilter,
        filters.UnitTypes,
        filters.Availability
      );
    } else if (from === 'length') {
      getUnitsListing(
        '',
        filters.Campus,
        filters.Status,
        buyValue,
        leaseValue,
        widthFilter,
        [0, 0],
        filters.UnitTypes,
        filters.Availability
      );
    } else {
      getUnitsListing(
        '',
        filters.Campus,
        filters.Status,
        buyValue,
        leaseValue,
        widthFilter,
        lengthFilter,
        filters.UnitTypes,
        filters.Availability
      );
    }
  };

  const clearPriceRange = (from) => {
    if (from === 'buyPrice') {
      setBuyValue([0, 0]);
      listingCallWithPriceRange(from);
    }

    if (from === 'leasePrice') {
      setLeaseValue([0, 0]);
      listingCallWithPriceRange(from);
    }

    if (from === 'width') {
      setWidthFilter([0, 0]);
      listingCallWithPriceRange(from);
    }

    if (from === 'length') {
      setLengthFilter([0, 0]);
      listingCallWithPriceRange(from);
    }
  };

  const handleClick = (event) => {
    if (!unitsListLoading) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickAwayHandler = () => {
    setAnchorEl(false);
  };
  // Closing Filter  Modal DropDown
  const handleClickForParent = (title) => {
    setListOpen({
      ...listOpen,
      [title]: !listOpen[title],
    });
  };
  // onChange Filter States
  const onChangeFilter = (item, index, e) => {
    const { name, value } = e.target;

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('page', '1');
      return params;
    });

    if (name === 'status') {
      let status = filters?.Status?.slice();
      let campus = filters?.Campus?.slice();
      let unitTypes = filters?.UnitTypes?.slice();
      let availability = filters?.Availability?.slice();

      setFilters(() => {
        if (index === 0) {
          status[index] = { title: item.title, value: !status[index].value };
          status[index + 1] = { title: status[index + 1].title, value: false };
        } else {
          status[index] = { title: item.title, value: !status[index].value };
          status[index - 1] = { title: status[index - 1].title, value: false };
        }
        const newObj = {
          Status: status,
          Campus: campus,
          UnitTypes: unitTypes,
          Availability: availability,
        };
        return newObj;
      });
    } else if (name === 'unittypes') {
      let status = filters?.Status?.slice();
      let campus = filters?.Campus?.slice();
      let unitTypes = filters?.UnitTypes?.slice();
      let availability = filters?.Availability?.slice();

      setFilters(() => {
        unitTypes[index].value = !unitTypes[index].value;
        const newObj = {
          Status: status,
          Campus: campus,
          UnitTypes: unitTypes,
          Availability: availability,
        };
        return newObj;
      });
    } else if (name === 'availability') {
      let status = filters.Status.slice();
      let campus = filters.Campus.slice();
      let unitTypes = filters.UnitTypes.slice();
      let availability = filters.Availability.slice();

      setFilters(() => {
        availability[index].value = !availability[index].value;
        const newObj = {
          Status: status,
          Campus: campus,
          UnitTypes: unitTypes,
          Availability: availability,
        };
        return newObj;
      });
    } else {
      let status = filters.Status.slice();
      let campus = filters.Campus.slice();
      let unitTypes = filters.UnitTypes.slice();
      let availability = filters.Availability.slice();

      setFilters(() => {
        // eslint-disable-next-line
        campus?.map((gItem) => {
          if (item.id === gItem.id) {
            return (gItem.value = !gItem.value);
          }
        });
        const newObj = {
          Campus: campus,
          Status: status,
          UnitTypes: unitTypes,
          Availability: availability,
        };
        return newObj;
      });
    }
  };

  // OnClear Filters
  const onClearFilter = () => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('page', '1');
      return params;
    });

    setFilters(() => {
      let status = filters?.Status?.slice();
      let campus = filters?.Campus?.slice();
      let unitTypes = filters?.UnitTypes?.slice();
      let availability = filters?.Availability?.slice();

      campus?.map((item) => {
        return (item.value = false);
      });

      unitTypes?.map((item) => {
        return (item.value = false);
      });

      availability[0].value = false;
      availability[1].value = false;

      status[0] = { title: status[0].title, value: false };
      status[1] = { title: status[1].title, value: false };

      const newObj = {
        Campus: campus,
        Status: status,
        UnitTypes: unitTypes,
        Availability: availability,
      };
      return newObj;
    });
    setBuyValue([0, 0]);
    setLeaseValue([0, 0]);

    setWidthFilter([0, 0]);
    setLengthFilter([0, 0]);

    // Calling Listing
    getUnitsListing();

    // Closing Filter Modal
    handleClose();

    // To Close DropDown
    setListOpen([]);
  };
  //************************* Filter End *******************************//

  //************************* Add Unit Start *******************************//

  // initial Values
  const initUnit = {
    newUnit: {
      id: '',
      facilityId: '',
      facilityName: '',
      unitNum: '',
      length: '',
      width: '',
      sqFeet: 'Please enter length and width of unit to calculate the Sq Ft',
      maintenanceFee: 'Please select campus to autofill maintenance fee',
      leasePrice12Month: null,
      leasePrice6Month: null,
      leasePriceMonthToMonth: null,
      description: '',
      availableForSale: false,
      availableForLease: false,
      leasePrice: '',
      buyPrice: '',
      status: false,
      owner: '',
      ownerId: '',
      ownerName: '',
      ownerEmail: '',
      ownerPhone: '',
      purchaseDate: '',
      lessee: '',
      lesseeId: '',
      lesseeName: '',
      lesseeEmail: '',
      lesseePhone: '',
      monthlyLeaseAmount: '',
      startDate: '',
      endDate: '',
      rentalFee: '',
      amenities: [],
      selectedAmenities: [],
      newUnitType: '',
      unitTitle: '',
      // unitDescription: '',
      unitPassword: '',
      unitVisibility: 'PUBLIC',
      unitPublished: true,
      unitDraft: false,
      storage: [],
      url: UNITBASEURLFORWEB,
      customURI: 'enter-uri',
      publishDate: date,
      seoUnitTitle: '',
      seoMetaDescription: '',
      seoKeywords: '',
    },
    unitError: {
      name: '',
      msg: '',
    },
    isEmpty: {
      userInfo: '',
      ownerInfo: '',
      lesseeInfo: '',
      unitPage: '',
    },
  };

  //***** States *****//
  const [isEmpty, setIsEmpty] = useState({
    userInfo: '',
    ownerInfo: '',
    lesseeInfo: '',
    unitPage: '',
  });

  const [newUnit, setNewUnit] = useState(initUnit.newUnit);
  const [editUnitData, setEditUnitData] = useState('');
  const [editDataLoading, setEditDataLoading] = useState(false);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [uriError, setUriError] = useState('');
  const [error, setError] = useState('');

  //***** Methods *****//

  const getUpdatedFields = (originalData, newData) => {
    const updatedFields = {};

    for (const key in newData) {
      // Handle nested objects
      if (
        typeof newData[key] === 'object' &&
        newData[key] !== null &&
        !Array.isArray(newData[key])
      ) {
        const nestedChanges = getUpdatedFields(
          originalData[key] || {},
          newData[key]
        );
        if (Object.keys(nestedChanges).length > 0) {
          updatedFields[key] = nestedChanges;
        }
      }
      // Handle arrays
      else if (Array.isArray(newData[key])) {
        if (Array.isArray(originalData[key])) {
          // Check if arrays have different lengths
          if (newData[key].length !== originalData[key].length) {
            updatedFields[key] = newData[key]; // Consider entire array as changed if lengths differ
          } else {
            const arrayChanges = [];

            // Loop through arrays and compare each element
            for (let i = 0; i < newData[key].length; i++) {
              const originalElement = originalData[key][i];
              const newElement = newData[key][i];

              // Recursively check for object differences inside the array
              if (typeof newElement === 'object' && newElement !== null) {
                const elementChanges = getUpdatedFields(
                  originalElement || {},
                  newElement
                );
                if (Object.keys(elementChanges).length > 0) {
                  arrayChanges.push(elementChanges);
                } else {
                  arrayChanges.push(null); // Indicate no change in this element
                }
              } else if (newElement !== originalElement) {
                // If a primitive type has changed, log the new element
                arrayChanges.push(newElement);
              } else {
                arrayChanges.push(null); // No changes
              }
            }

            // If any changes detected in the array, update the key
            if (arrayChanges.some((change) => change !== null)) {
              updatedFields[key] = newData[key];
            }
          }
        } else {
          updatedFields[key] = newData[key]; // If originalData isn't an array, consider it a change
        }
      }
      // Handle primitive types: update if the values are different
      else if (newData[key] !== originalData[key]) {
        updatedFields[key] = newData[key];
      }
    }

    return updatedFields;
  };

  const onChangeUnits = (e) => {
    let { name, value } = e.target;
    const unitNumReg = /^\S*$/;
    if (
      name === 'width' ||
      name === 'length' ||
      name === 'leasePrice' ||
      name == 'buyPrice'
    ) {
      value = value?.replace(/[^\d.]/g, '');
      setNewUnit({
        ...newUnit,
        [name]: value,
      });
    } else if (name === 'unitNum') {
      if (unitNumReg.test(value)) {
        let keywords = [];
        keywords?.push(value);
        keywords?.push(newUnit?.facilityName?.split(' ')?.join('-'));
        keywords?.push(...newUnit?.selectedAmenities);

        setNewUnit({
          ...newUnit,
          [name]: value,
          seoUnitTitle:
            newUnit?.facilityName +
            `${newUnit?.facilityName !== '' && value !== '' ? '-' : ''}` +
            value,
          seoKeywords: keywords
            ?.filter(
              (item) => item !== '' && item !== null && item !== undefined
            )
            ?.join(','),
        });
      }
    } else if (name === 'seoKeywords') {
      setNewUnit({
        ...newUnit,
        [name]: value.endsWith(',') ? value : value?.replace(/\s+/g, ','),
      });
    } else if (name === 'unitTitle') {
      setNewUnit({
        ...newUnit,
        customURI:
          value === ''
            ? 'enter-uri'
            : value?.toLowerCase()?.replaceAll(' ', '-'),
        [name]: value,
        seoMetaDescription:
          value +
          `${
            value !== '' && newUnit?.facilityName !== '' ? ' exist in ' : ''
          }` +
          newUnit?.facilityName,
      });
    } else {
      setNewUnit({
        ...newUnit,
        [name]: value,
      });
    }
    setError('');
    setIsEmpty(initUnit?.isEmpty);
  };

  // Handle onChange URL
  const handleOnChangeURL = (event) => {
    if (event.target.name === 'publishDate') {
      setNewUnit((prev) => ({
        ...prev,
        publishDate: event.target.value,
      }));
    }
    const inputValue = event.target.value;
    const prefix = newUnit.url;
    const regex = /[^a-zA-Z0-9-]+/g;
    const value = inputValue.split('/')[4];
    if (!regex.test(value) || value === '') {
      if (inputValue.startsWith(prefix)) {
        const cleanedValue = value.replace(/-+/g, '-');
        setNewUnit((prev) => ({
          ...prev,
          customURI: inputValue
            .substring(prefix.length)
            .replace(value, cleanedValue),
        }));
      }
      setUriError('');
    } else {
      setUriError("You can't use underscores and special characters");
    }
  };

  const onCloseAddEditUnit = () => {
    setNewUnit(initUnit.newUnit);
    setIsEmpty(initUnit?.isEmpty);
    setAddEditLoading(false);
    closeAddEditUnit();
    setError('');
    setImages([]);
    setDeletedImages([]);
  };

  // eslint-disable-next-line
  const onEmptyCheck = () => {
    let payload = createUnitPayload();
    const unitPage = {
      title: newUnit?.unitTitle,
      // description: payload?.unit_page?.description?.replaceAll(/<[^>]*>/g, ''),
      customURL: newUnit?.customURI === 'enter-uri' ? '' : newUnit?.customURI,
    };

    let isUnitInfoEmpty = checkObjectEmpty(
      payload?.unit_info,
      'isUnitInfoEmpty'
    );
    let isOwnerInfoEmpty =
      payload?.owner_info &&
      payload?.owner_info !== null &&
      payload.owner !== undefined
        ? checkObjectEmpty(payload?.owner_info, 'isOwnerInfoEmpty')
        : {};
    let isLesseeInfoEmpty =
      payload?.lessee_info &&
      payload?.lessee_info !== null &&
      payload.lessee !== undefined
        ? checkObjectEmpty(payload?.lessee_info, 'isLesseeInfoEmpty')
        : {};

    let isUnitPageEmpty = checkObjectEmpty(unitPage, 'isUnitPageEmpty');

    if (
      Object.values(isUnitInfoEmpty).length > 0 ||
      Object.values(isOwnerInfoEmpty).length > 0 ||
      Object.values(isLesseeInfoEmpty).length > 0 ||
      Object.values(isUnitPageEmpty).length > 0
    ) {
      setIsEmpty({
        userInfo: isUnitInfoEmpty,
        ownerInfo: isOwnerInfoEmpty,
        lesseeInfo: isLesseeInfoEmpty,
        unitPage: isUnitPageEmpty,
      });
      return true;
    } else {
      return false;
    }
  };

  const checkObjectEmpty = (arrayToCheck, name) => {
    let obj = {};

    // eslint-disable-next-line
    Object.values(arrayToCheck).map((item, index) => {
      if (!newUnit.availableForSale && !newUnit.availableForLease) {
        if (
          (item === null || item === '' || item === 0 || item === undefined) &&
          Object.keys(arrayToCheck)[index] !== 'buy_price' &&
          Object.keys(arrayToCheck)[index] !== 'lease_price'
        ) {
          obj = {
            ...obj,
            [Object.keys(arrayToCheck)[index]]: true,
            [name]: true,
          };
        }
      } else if (!newUnit.availableForSale) {
        if (
          (item === null || item === '' || item === 0 || item === undefined) &&
          Object.keys(arrayToCheck)[index] !== 'buy_price'
        ) {
          obj = {
            ...obj,
            [Object.keys(arrayToCheck)[index]]: true,
            [name]: true,
          };
        }
      } else if (!newUnit.availableForLease) {
        if (
          (item === null || item === '' || item === 0 || item === undefined) &&
          Object.keys(arrayToCheck)[index] !== 'lease_price'
        ) {
          obj = {
            ...obj,
            [Object.keys(arrayToCheck)[index]]: true,
            [name]: true,
          };
        }
      } else {
        if (item === null || item === '' || item === 0 || item === undefined) {
          obj = {
            ...obj,
            [Object.keys(arrayToCheck)[index]]: true,
            [name]: true,
          };
        }
      }
    });

    return obj;
  };

  const createUnitPayload = (from) => {
    if (from === 'Edit') {
      delete newUnit.leasePrice6Month;
      delete newUnit.leasePrice12Month;
      delete newUnit.leasePriceMonthToMonth;
      newUnit.description = newUnit?.description?.replace(/<[^>]*>/g, '');
      copyUnitsData.description = copyUnitsData?.description?.replace(
        /<[^>]*>/g,
        ''
      );
      copyUnitsData.sqFeet = parseInt(copyUnitsData?.sqFeet);
      copyUnitsData.sqFeet = parseInt(copyUnitsData?.sqFeet);
      const newPayload = getUpdatedFields(copyUnitsData, newUnit);
      const newStorage = newPayload?.storage?.map((item) => item.name);
      let payload = {
        unit_info: {
          facility: newUnit.facilityId,
          ...(newPayload?.unitNum && { unit_number: newPayload?.unitNum }),
          ...(newPayload?.length && {
            length: parseFloat(newPayload?.length)?.toFixed(2),
          }),
          ...(newPayload?.width && {
            width: parseFloat(newPayload?.width)?.toFixed(2),
          }),
          ...(newPayload?.description && {
            unit_description: newPayload?.description,
          }),
          ...(newPayload?.leasePrice && {
            lease_price: newPayload?.leasePrice,
          }),
          ...(newPayload?.buyPrice && { buy_price: newPayload?.buyPrice }),
          ...(newPayload?.status !== undefined && {
            is_active: newPayload?.status,
          }),
          ...(newPayload?.availableForSale !== undefined && {
            is_available_for_sale: newPayload?.availableForSale,
          }),
          ...(newPayload?.availableForLease !== undefined && {
            is_available_for_lease: newPayload?.availableForLease,
          }),
          ...(newPayload?.newUnitType && {
            unit_type: newPayload?.newUnitType?.toUpperCase(),
          }),
          ...(newStorage?.length > 0 && {
            storage_feature:
              newStorage?.length === storageList?.length
                ? ['ALL TYPES']
                : newStorage,
          }),
        },
        ...(newPayload?.amenities && { amenity_ids: newPayload?.amenities }),
        ...(((editUnitData?.ownerObject &&
          (editUnitData?.ownerObject?.owner_id !== newPayload.ownerId ||
            editUnitData?.ownerObject?.purchase_date !==
              newPayload.purchaseDate)) ||
          (!editUnitData?.ownerObject && newPayload.ownerId !== '')) && {
          owner_info:
            newPayload.availableForSale && newPayload.ownerId !== ''
              ? {
                  owner: newPayload.ownerId,
                  purchase_date: newPayload.purchaseDate,
                }
              : null,
        }),
        ...(((editUnitData?.lesseeObject &&
          (editUnitData?.lesseeObject?.lessee_id !== newPayload.lesseeId ||
            editUnitData?.lesseeObject?.start_date !== newPayload.startDate ||
            editUnitData?.lesseeObject?.end_date !== newPayload.endDate)) ||
          (!editUnitData?.lesseeObject && newPayload.lesseeId !== '')) && {
          lessee_info:
            newPayload.availableForLease && newPayload.lesseeId !== ''
              ? {
                  lessee: newPayload.lesseeId,
                  start_date: newPayload.startDate,
                  end_date: newPayload.endDate,
                }
              : null,
        }),
        unit_page: {
          ...(newPayload?.unitTitle && { title: newPayload?.unitTitle }),
          ...(newPayload?.unitDraft !== undefined && {
            publish: newPayload?.unitDraft ? false : true,
          }),
          ...(newPayload?.unitVisibility && {
            visibility: newPayload?.unitVisibility
              ? newPayload?.unitVisibility
              : 'PUBLIC',
          }),
          ...(newPayload?.unitPassword && {
            password: newPayload?.unitPassword,
          }),
          ...(newPayload.publishDate && {
            unit_publish_date: `${newPayload.publishDate}T00:00:00Z`,
          }),
          ...(newPayload?.customURI && {
            end_url_during_update: newPayload?.customURI,
          }),
          ...(newPayload?.seoUnitTitle && {
            unit_seo_title: newPayload?.seoUnitTitle,
          }),
          ...(newPayload?.seoMetaDescription && {
            unit_seo_description: newPayload?.seoMetaDescription,
          }),
          ...(newPayload?.seoKeywords &&
            newPayload?.seoKeywords?.trim() !== '' && {
              unit_seo_keywords: newPayload?.seoKeywords
                ?.trim()
                .split(',')
                ?.filter((item) => item !== '' && item !== ' '),
            }),
        },
      };
      return payload;
    } else {
      const newStorage = newUnit?.storage?.map((item) => item.name);
      let payload = {
        unit_info: {
          facility: newUnit.facilityId,
          unit_number: newUnit?.unitNum,
          length: newUnit?.length
            ? parseFloat(newUnit?.length)?.toFixed(2)
            : null,
          width: newUnit?.width ? parseFloat(newUnit?.width)?.toFixed(2) : null,
          // sq_ft: newUnit.sqFeet,
          // maintenance_fee: newUnit.maintenanceFee,
          unit_description:
            newUnit?.description === '' ? '' : newUnit?.description,
          lease_price: newUnit?.leasePrice === '' ? 0 : newUnit?.leasePrice,
          buy_price: newUnit?.buyPrice === '' ? 0 : newUnit?.buyPrice,
          is_active: newUnit?.status,
          is_available_for_sale: newUnit?.availableForSale,
          is_available_for_lease: newUnit?.availableForLease,
          unit_type: newUnit?.newUnitType?.toUpperCase(),
          storage_feature:
            newStorage?.length === storageList?.length
              ? ['ALL TYPES']
              : newStorage,
        },
        amenity_ids: newUnit?.amenities,
        ...(((editUnitData?.ownerObject &&
          (editUnitData?.ownerObject?.owner_id !== newUnit.ownerId ||
            editUnitData?.ownerObject?.purchase_date !==
              newUnit.purchaseDate)) ||
          (!editUnitData?.ownerObject && newUnit.ownerId !== '')) && {
          owner_info:
            newUnit.availableForSale && newUnit.ownerId !== ''
              ? {
                  owner: newUnit.ownerId,
                  purchase_date: newUnit.purchaseDate,
                }
              : null,
        }),
        ...(((editUnitData?.lesseeObject &&
          (editUnitData?.lesseeObject?.lessee_id !== newUnit.lesseeId ||
            editUnitData?.lesseeObject?.start_date !== newUnit.startDate ||
            editUnitData?.lesseeObject?.end_date !== newUnit.endDate)) ||
          (!editUnitData?.lesseeObject && newUnit.lesseeId !== '')) && {
          lessee_info:
            newUnit.availableForLease && newUnit.lesseeId !== ''
              ? {
                  lessee: newUnit.lesseeId,
                  start_date: newUnit.startDate,
                  end_date: newUnit.endDate,
                }
              : null,
        }),
        unit_page: {
          title: newUnit?.unitTitle,
          // description: newUnit?.unitDescription,
          publish: newUnit?.unitDraft ? false : true,
          visibility: newUnit?.unitVisibility
            ? newUnit?.unitVisibility
            : 'PUBLIC',
          password: newUnit?.unitPassword,
          unit_publish_date: `${newUnit.publishDate}T00:00:00Z`,
          ...(from === 'Add'
            ? {
                end_url: newUnit?.customURI,
              }
            : {
                end_url_during_update: newUnit?.customURI,
              }),
          unit_seo_title: newUnit?.seoUnitTitle || null,
          unit_seo_description: newUnit?.seoMetaDescription || null,
          unit_seo_keywords:
            newUnit?.seoKeywords?.trim() !== ''
              ? newUnit?.seoKeywords
                  ?.trim()
                  .split(',')
                  ?.filter((item) => item !== '' && item !== ' ')
              : null,
        },
      };
      return payload;
    }
  };

  const onDeleteUnitPage = () => {
    setAddEditLoading(true);
    ApiController.deleteUnitPageCall(newUnit?.unitId, (response) => {
      if (response.success) {
        onSuccesfullyEditUnit();
        setAddEditLoading(false);
        setIsEmpty(initUnit?.isEmpty);
      } else {
        setIsEmpty(initUnit?.isEmpty);
        setAddEditLoading(false);
      }
    });
  };
  const onSavePressFromAddEdit = (from) => {
    setAddEditLoading(true);
    let payload = createUnitPayload(from);
    Object.keys(payload).forEach((key) => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });

    if (from === 'Add') {
      // Clear Filters
      setFilters(() => {
        let status = filters?.Status?.slice();
        let campus = filters?.Campus?.slice();
        let unitTypes = filters?.UnitTypes?.slice();
        let availability = filters?.Availability?.slice();

        campus?.map((item) => {
          return (item.value = false);
        });

        unitTypes?.map((item) => {
          return (item.value = false);
        });

        availability[0].value = false;
        availability[1].value = false;

        status[0] = { title: status[0].title, value: false };
        status[1] = { title: status[1].title, value: false };

        const newObj = {
          Campus: campus,
          Status: status,
          UnitTypes: unitTypes,
          Availability: availability,
        };
        return newObj;
      });

      // Clear Buy and Lease
      setBuyValue([0, 0]);
      setLeaseValue([0, 0]);

      // Clear Width and Length
      setWidthFilter([0, 0]);
      setLengthFilter([0, 0]);

      // To Close DropDown
      setListOpen([]);

      // Call Save API
      if (!onEmptyCheck()) {
        ApiController.addNewUnitCall(payload, (response) => {
          if (response.success) {
            if (Images.length > 0) {
              uploadImages(response?.data?.Unit_id);
            }
            setAddEditLoading(false);
            setNewUnit(initUnit.newUnit);
            setIsEmpty(initUnit?.isEmpty);
            onCloseAddEditUnit();
            const isFilters = filterString(filters?.Campus, filters?.Status);
            getUnitsListing();
          } else {
            {
              response?.data?.[0]?.unit_number &&
                setError(response?.data?.[0]?.unit_number);
            }
            {
              response?.data?.[0]?.unit && setError(response?.data?.[0]?.unit);
            }
            setAddEditLoading(false);
            {
              response?.data?.[0]?.Stripe &&
                setError(
                  toUpperCase(response?.data?.[0]?.Stripe?.toLowerCase())
                );
            }
            setIsEmpty(initUnit?.isEmpty);
          }
        });
      } else {
        setAddEditLoading(false);
      }
    } else {
      if (!onEmptyCheck()) {
        ApiController.updateUnitCall(newUnit?.id, payload, (response) => {
          if (response.success) {
            if (Images?.length > 0 || deletedImages?.length > 0) {
              checkforImageUploadAndDelete();
              onSuccesfullyEditUnit();
            } else {
              onSuccesfullyEditUnit();
            }
          } else {
            {
              response?.data?.[0]?.unit_number &&
                setError(response?.data?.[0]?.unit_number);
            }
            {
              response?.data?.[0]?.unit_description &&
                setError(
                  response?.data?.[0]?.unit_description?.replace(
                    'this',
                    'description'
                  )
                );
            }
            {
              response?.data[0]?.non_field_errors &&
                setError(response?.data[0]?.non_field_errors?.split(':')[0]);
            }
            {
              response?.data[0]?.end_url &&
                setError(
                  response?.data[0]?.end_url?.replace('unit_page', 'Unit page')
                );
            }
            setIsEmpty(initUnit?.isEmpty);
            setAddEditLoading(false);
          }
        });
      } else {
        setAddEditLoading(false);
      }
    }
  };
  const checkforImageUploadAndDelete = () => {
    if (
      Images.length > 0 &&
      Images?.filter((item) => typeof item === 'object')
    ) {
      // Images?.map((item) => {
      // 	if (typeof item === "object" && item?.name) {
      uploadImages(newUnit?.id);
      // 	}
      // });
    }
    if (deletedImages?.length > 0) {
      deleteImages(deletedImages);
    }
  };
  const onSuccesfullyEditUnit = () => {
    setAddEditLoading(false);
    setNewUnit(initUnit.newUnit);
    setIsEmpty(initUnit?.isEmpty);
    onCloseAddEditUnit();
    getUnitsListing(
      searchText,
      filters?.Campus,
      filters?.Status,
      buyValue,
      leaseValue,
      widthFilter,
      lengthFilter,
      filters?.UnitTypes,
      filters?.Availability
    );
  };

  //////////// Image Related Tasks ////////////
  const [Images, setImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const createImagePayload = () => {
    let formData = new FormData();
    Images?.map((item, index) => {
      return index === 0
        ? formData.append('image', item)
        : formData.append(`image${index}`, item);
    });
    return formData;
  };

  const uploadImages = (id) => {
    ApiController.addUnitsImagesCall(id, createImagePayload(), (response) => {
      if (response.success) {
      } else {
      }
    });
  };

  const deleteImages = (id) => {
    ApiController.deleteUnitsImagesCall({ ids: id }, (response) => {
      if (response.success) {
      } else {
      }
    });
  };

  //************************* Add Unit End *******************************//

  //************************* Unit Details Start *******************************//

  const unitDetailsInitValue = {
    campus: '',
    unitNumber: '',
    owner: '',
    accessDigitalOutput: '',
    accessPort: '',
    length: '',
    width: '',
    deviceSerialNumber: '',
    maintenanceFee: '',
    documents: '',
    availableForSale: '',
    buyPrice: '',
    availableForLease: '',
    leasePrice: '',
    insuranceType: '',
    policyNumber: '',
    policyStartDate: '',
    policyEndDate: '',
  };
  //***** States *****//
  const [unitDetailsModal, toggleUnitDetailsModal] = useState(false);
  const [unitDetails, setUnitDetails] = useState(unitDetailsInitValue);
  const [unitDetailsLoading, setUnitDetailsLoading] = useState(false);

  //***** Methods *****//
  const onClickEdit = (unit) => {
    toggleUnitDetailsModal(false);
    const unitData = unit?.rawRowData;
    const rawData = unit?.rawData;
    const pageData =
      unit?.rawData?.unit_page !== null ? unit?.rawData?.unit_page : {};
    const amenityIds = rawData?.unit_amenities?.map(
      (amenity) => amenity?.amenity_id
    );
    const facilityIds = rawData?.facility_amenities?.map(
      (amenity) => amenity?.amenity_id
    );
    const finalAmmenityIds = [...new Set(amenityIds?.concat(facilityIds))];
    unit?.rawData?.storage_feature &&
    unit?.rawData?.storage_feature[0] === 'ALL TYPES'
      ? setStorageSelectAll(true)
      : setStorageSelectAll(false);

    setEditUnitData(unitData);
    setImages(unit?.rawData?.unit_image_url);
    const selectedAmenities = rawData?.facility_amenities?.map((item) => {
      return item?.amenity__name;
    });

    setNewUnit({
      title: 'Edit Unit',
      id: unitData?.id,
      description: unitData?.description,
      facilityId: unitData?.facilityId,
      facilityName: unitData?.facility,
      unitNum: unitData?.unitNumber,
      length: unitData?.length,
      width: unitData?.width,
      sqFeet: unitData?.sqFt,
      maintenanceFee: unitData?.maintenanceFee,
      availableForSale: unitData?.forSale,
      availableForLease: unitData?.forLease,
      leasePrice: unitData?.leasePrice,
      buyPrice: unitData?.buyPrice,
      amenities: finalAmmenityIds,
      status: unitData?.status,
      ...(typeof rawData?.unit_owner === 'object' && {
        owner: rawData?.unit_owner,
        ownerId: rawData?.unit_owner?.owner_id,
        ownerName: `${rawData?.unit_owner?.first_name} ${rawData?.unit_owner?.last_name}`,
        ownerEmail: rawData?.unit_owner?.email,
        ownerPhone:
          rawData?.unit_owner?.phone_no !== null
            ? rawData?.unit_owner?.phone_no
            : 'No phone number linked with selected user',
        purchaseDate: rawData?.unit_owner?.purchase_date,
        sellDate: rawData?.unit_owner?.sell_date,
      }),
      ...(typeof rawData?.unit_lessee === 'object' && {
        lessee: rawData?.unit_lessee,
        lesseeId: rawData?.unit_lessee?.lessee_id,
        lesseeName: `${rawData?.unit_lessee?.first_name} ${rawData?.unit_lessee?.last_name}`,
        lesseeEmail: rawData?.unit_lessee?.email,
        lesseePhone:
          rawData?.unit_lessee?.phone_no !== null
            ? rawData?.unit_lessee?.phone_no
            : 'No phone number linked with selected user',
        startDate: rawData?.unit_lessee?.start_date,
        endDate: rawData?.unit_lessee?.end_date,
      }),
      url: UNITBASEURLFORWEB,
      customURI: pageData?.end_url !== '' ? pageData?.end_url : 'enter-uri',
      publishDate:
        pageData?.unit_publish_date !== null
          ? pageData?.unit_publish_date?.split('T')[0]
          : date,
      unitDescription: pageData?.description,
      unitTitle: pageData?.title,
      unitPassword: pageData?.password,
      unitVisibility: pageData?.visibility,
      unitDraft: pageData?.publish ? false : true,
      storage:
        rawData?.storage_feature !== null
          ? rawData?.storage_feature[0] === 'ALL TYPES'
            ? storageList
            : storageList?.filter((item) =>
                rawData?.storage_feature?.includes(item?.name)
              )
          : null,
      newUnitType: rawData?.unit_type,
      selectedAmenities: selectedAmenities,
      seoUnitTitle: pageData?.unit_seo_title || '',
      seoMetaDescription: pageData?.unit_seo_description || '',
      seoKeywords: pageData?.unit_seo_keywords?.join(',') || '',
    });

    setTimeout(() => {
      toggleAddEditUnitModal(true);
    }, 100);
  };

  const fetchUnitDetails = (unitData) => {
    setUnitDetailsLoading(true);
    ApiController.unitDetailsCall(unitData.id, (response) => {
      if (response.success) {
        const unitDatails = response?.data;
        unitData.description = unitDatails?.unit_page?.description;
        let insuranceDoc, agreementDoc;

        unitDatails?.storage_feature &&
        unitDatails?.storage_feature[0] === 'ALL TYPES'
          ? setStorageSelectAll(true)
          : setStorageSelectAll(false);

        if (unitDatails?.unit_lessee?.insurance_documents) {
          insuranceDoc = {
            type: 'Insurance Policy',
            name: unitDatails?.unit_number + ' Insurance',
            viewUrl: unitDatails?.unit_lessee?.insurance_documents?.replace(
              'download',
              'view'
            ),
            downloadUrl: unitDatails?.unit_lessee?.insurance_documents,
          };
        }

        if (unitDatails?.unit_lessee?.agreement_documents) {
          agreementDoc = {
            type: 'Lease Agreement',
            name: unitDatails?.unit_number + ' Lease',
            viewUrl: unitDatails?.unit_lessee?.agreement_documents?.replace(
              'download',
              'view'
            ),
            downloadUrl: unitDatails?.unit_lessee?.agreement_documents,
          };
        }

        const amenityIds = unitDatails?.unit_amenities?.map(
          (amenity) => amenity?.amenity_id
        );
        const selectedAmenities = unitDatails?.facility_amenities?.map(
          (item) => item?.amenity__name
        );

        setCopyUnitsData({
          title: 'Edit Unit',
          id: unitDatails?.id,
          description: unitDatails?.unit_page?.description,
          url: UNITBASEURLFORWEB,
          customURI: unitDatails?.unit_page?.end_url
            ? unitDatails?.unit_page?.end_url
            : 'enter-uri',
          publishDate: unitDatails?.unit_publish_date
            ? unitDatails?.unit_publish_date?.split('T')[0]
            : date,
          unitPassword: unitDatails?.password,
          facilityId: unitDatails?.facility_id,
          facilityName: unitDatails?.facility_name,
          unitNum: unitDatails?.unit_number,
          length: unitDatails?.length,
          width: unitDatails?.width,
          sqFeet: unitDatails?.sq_ft,
          maintenanceFee: unitData?.maintenanceFee,
          availableForSale: unitDatails?.is_available_for_sale,
          availableForLease: unitDatails?.is_available_for_lease,
          leasePrice: unitDatails?.lease_price,
          buyPrice: unitDatails?.buy_price,
          status: unitData?.status,
          newUnitType: unitDatails?.unit_type,
          unitTitle: unitDatails?.unit_page?.title,
          unitId: unitDatails?.unit_page?.id,
          unitDescription: unitDatails?.unit_page?.description,
          unitPassword: unitDatails?.unit_page?.password,
          unitVisibility: unitDatails?.unit_page?.visibility,
          unitDraft: unitDatails?.unit_page?.publish ? false : true,
          storage:
            unitDatails?.storage_feature !== null
              ? unitDatails?.storage_feature[0] === 'ALL TYPES'
                ? storageList
                : storageList?.filter((item) =>
                    unitDatails?.storage_feature?.includes(item?.name)
                  )
              : null,
          amenities: JSON.parse(JSON.stringify(amenityIds)),
          ...(typeof unitDatails?.unit_owner === 'object' && {
            owner: unitDatails?.unit_owner,
            ownerId: unitDatails?.unit_owner?.owner_id,
            ownerName: `${unitDatails?.unit_owner?.first_name} ${unitDatails?.unit_owner?.last_name}`,
            ownerEmail: unitDatails?.unit_owner?.email,
            ownerPhone:
              unitDatails?.unit_owner?.phone_no !== null
                ? unitDatails?.unit_owner?.phone_no
                : 'No phone number linked with selected user',
            purchaseDate: unitDatails?.unit_owner?.purchase_date,
            sellDate: unitDatails?.unit_owner?.sell_date,
          }),
          ...(typeof unitDatails?.unit_lessee === 'object' && {
            lessee: unitDatails?.unit_lessee,
            lesseeId: unitDatails?.unit_lessee?.lessee_id,
            lesseeName: `${unitDatails?.unit_lessee?.first_name} ${unitDatails?.unit_lessee?.last_name}`,
            lesseeEmail: unitDatails?.unit_lessee?.email,
            lesseePhone:
              unitDatails?.unit_lessee?.phone_no !== null
                ? unitDatails?.unit_lessee?.phone_no
                : 'No phone number linked with selected user',
            startDate: unitDatails?.unit_lessee?.start_date,
            endDate: unitDatails?.unit_lessee?.end_date,
          }),
          selectedAmenities: selectedAmenities,
          seoUnitTitle: unitDatails?.unit_page?.unit_seo_title || '',
          seoMetaDescription:
            unitDatails?.unit_page?.unit_seo_description || '',
          seoKeywords:
            unitDatails?.unit_page?.unit_seo_keywords?.join(',') || '',
        });

        const obj = {
          rawData: unitDatails,
          rawRowData: unitData,
          campus: unitDatails?.facility_name ? unitDatails?.facility_name : '-',
          unitNumber: unitDatails?.unit_number ? unitDatails?.unit_number : '-',
          owner: unitData?.owner ? unitData?.owner : '-',
          accessDigitalOutput: unitDatails?.access_digital_output
            ? unitDatails?.access_digital_output
            : '-',
          accessPort: unitDatails?.access_port ? unitDatails?.access_port : '-',
          length: unitDatails?.length ? unitDatails?.length : '-',
          width: unitDatails?.width ? unitDatails?.width : '-',
          deviceSerialNumber: unitDatails?.device_serial_number
            ? unitDatails?.device_serial_number
            : '-',
          maintenanceFee: unitData?.maintenanceFee
            ? unitData?.maintenanceFee
            : '-',
          insuranceDocument: insuranceDoc ? insuranceDoc : '',
          leaseDocument: agreementDoc ? agreementDoc : '',
          availableForSale: unitDatails?.is_available_for_sale
            ? unitDatails?.is_available_for_sale
            : '-',
          buyPrice: unitDatails?.buy_price ? unitDatails?.buy_price : '-',
          availableForLease: unitDatails?.is_available_for_lease
            ? unitDatails?.is_available_for_lease
            : '-',
          leasePrice: unitDatails?.lease_price ? unitDatails?.lease_price : '-',
          insuranceType: unitDatails?.unit_lessee
            ? unitDatails?.unit_lessee?.self_insurance_policy_number !== null
              ? 'Self'
              : 'LuxeLocker'
            : '-',
          policyNumber: unitDatails?.unit_lessee?.self_insurance_policy_number
            ? unitDatails?.unit_lessee?.self_insurance_policy_number
            : '-',
          policyStartDate: unitDatails?.unit_lessee?.start_date
            ? unitDatails?.unit_lessee?.start_date
            : '-',
          policyEndDate: unitDatails?.unit_lessee?.end_date
            ? unitDatails?.unit_lessee?.end_date
            : '-',
        };

        setUnitDetails(obj);
        setUnitDetailsLoading(false);
      } else {
        setUnitDetailsLoading(false);
        setNetworkError(true);
      }
    });
  };

  const onRowSelect = (unitData) => {
    fetchUnitDetails(unitData);
    toggleUnitDetailsModal(true);
  };

  //************************* Unit Details End *******************************//

  //************************* Amenities Listing Start *******************************//

  const getAmenityListing = (name, callback) => {
    ApiController.fetchCampusesCall(name, '', newUnit?.status, (response) => {
      if (response?.success) {
        const amenitiesData = response?.data[0]?.amenities || [];
        callback(amenitiesData);
      } else {
        setNetworkError(true);
        callback([]);
      }
    });
  };

  //************************* Amenities Listing End *******************************//

  //************************* Delete Unit Start *******************************//

  //***** States *****//
  const [deleteUnitModal, toggledeleteUnitModal] = useState(false);
  const [deleteUnitLoading, setDeleteUnitLoading] = useState(false);

  const [deleteUnit, setDeleteUnit] = useState({});

  //***** Methods *****//

  const onDelete = (id) => {
    setDeleteUnitLoading(true);
    ApiController.deleteUnitCall(id, (response) => {
      if (response.success) {
        setDeleteUnit({});
        getUnitsListing(
          searchText,
          filters?.Campus,
          filters?.Status,
          buyValue,
          leaseValue,
          widthFilter,
          lengthFilter,
          filters?.UnitTypes,
          filters?.Availability
        );
        setDeleteUnitLoading(false);
        toggledeleteUnitModal(false);
      } else {
        setDeleteUnitLoading(false);
        {
          response?.data[0]?.['UNIT HAVE ACTIVE ALLOTMENT'] &&
            setError(response?.data[0]?.['UNIT HAVE ACTIVE ALLOTMENT']);
        }
      }
    });
  };

  //************************* Delete Unit End *******************************//
  useEffect(() => {
    getUnitsListing(
      searchText,
      filters?.Campus,
      filters?.Status,
      buyValue,
      leaseValue,
      widthFilter,
      lengthFilter,
      filters?.UnitTypes,
      filters?.Availability
    );
  }, [searchParams.get('page'), searchParams.get('pageSize'), filters]);

  return (
    <>
      <UnitsUI
        // Selestion State
        selectionIds={selectionIds}
        setSelectionIds={setSelectionIds}
        // Table Data
        unitColumnData={unitsColumnData}
        unitColumnExtensionsData={unitsColumnExtensionsData}
        // unitRowData={unitsRowData}
        unitRowData={units?.length > 0 ? units : []}
        // Table Methods
        dataProviders={dataProviders}
        // Open Unit Details Modal
        onRowSelect={(userDate) => onRowSelect(userDate)}
        // SearchBar States
        searchText={searchText}
        // SearchBar Method
        onChangeSearch={onChangeSearch}
        onUnitsSearch={onUnitsSearch}
        onClear={onClear}
        // Filter Modal States
        anchorEl={anchorEl}
        id={id}
        open={open}
        listOpen={listOpen}
        filters={filters}
        selectedCampusString={'Spanish Springs, Boise, ID Phase 2'}
        // Filter Modal Methods
        handleClick={handleClick}
        handleClose={handleClose}
        clickAwayHandler={clickAwayHandler}
        handleClickForParent={handleClickForParent}
        onChangeFilter={onChangeFilter}
        onClearFilter={onClearFilter}
        clearPriceRange={clearPriceRange}
        listingCallWithPriceRange={listingCallWithPriceRange}
        // Filter Buy Range
        handlePriceChanges={handlePriceChanges}
        buyValue={buyValue}
        leaseValue={leaseValue}
        lengthFilter={lengthFilter}
        widthFilter={widthFilter}
        // Loading to show view after API Call
        loading={unitsListLoading}
        networkError={networkError}
        reCallListing={reCallListing}
        isSelectedCampus={isSelectedCampus}
        setIsSelectedCampus={setIsSelectedCampus}
        openModal={openAddEditUnit}
        metaData={metaData}
      />

      {/************************* Modals *******************************/}

      {/* Add / Edit Unit Modal */}
      <AddEditUnitUI
        open={openAddEditUnit}
        close={onCloseAddEditUnit}
        campuses={filters?.Campus}
        newUnit={newUnit}
        setNewUnit={setNewUnit}
        leasePrice={leasePrice}
        setLeasePrice={setLeasePrice}
        onChange={onChangeUnits}
        loading={addEditLoading}
        onSave={onSavePressFromAddEdit}
        onDeleteUnitPage={onDeleteUnitPage}
        isEmpty={isEmpty}
        setIsEmptyToInit={() => setIsEmpty(initUnit?.isEmpty)}
        error={error}
        Images={Images}
        setImages={setImages}
        data={unitDetails}
        deletedImages={deletedImages}
        setDeletedImages={setDeletedImages}
        editDataLoading={editDataLoading}
        getAmenityListing={getAmenityListing}
        storageList={storageList}
        storageSelectAll={storageSelectAll}
        setStorageSelectAll={setStorageSelectAll}
        uriError={uriError}
        onChangeURI={handleOnChangeURL}
      />

      {/* Unit Details */}
      <UnitDetails
        open={unitDetailsModal}
        close={() => {
          setUnitDetails(unitDetailsInitValue);
          toggleUnitDetailsModal(false);
        }}
        data={unitDetails}
        onClickEdit={onClickEdit}
        loading={unitDetailsLoading}
        recallList={fetchUnitDetails}
      />

      {/* Delete Unit */}
      <DeleteUnit
        open={deleteUnitModal}
        close={() => {
          setError(''), toggledeleteUnitModal(false);
        }}
        data={deleteUnit}
        onDelete={onDelete}
        loading={deleteUnitLoading}
        error={error}
      />

      {/* Import Data CSV Modal */}
      <ImportUnitsModal
        open={openCSVModal}
        closeImportDataModal={closeImportDataModal}
        listingCall={getUnitsListing}
        facilitiesList={facilitiesList}
      />
    </>
  );
};

export default UnitsTab;
