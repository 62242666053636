// Library Imports
import React, { useEffect, useState } from 'react';

// Local Imports
import CheckBox from '../../../../../../components/checkBox';
import { Styles } from '../../../../../../utils/style/GlobalStyles';
import { white, yellow } from '../../../../../../utils/style/GlobalVariables';
import { camalize } from '../../../../../../utils/validations/localValidations';
import { InsideSpinner } from '../../../../../../components/Spinner/Spinner';

const Amenities = ({
  newUnit,
  setNewUnit,
  amenities,
  setAmenities,
  amenityListLoading,
  setAmenityListLoading,
}) => {


  const onAmenitySelect = (item) => {
    const selectedAmenities = newUnit.amenities || [];
    const index = selectedAmenities?.indexOf(item.id);
    if (index > -1) {
      selectedAmenities?.splice(index, 1);
    } else {
      selectedAmenities?.length > 0
        ? selectedAmenities?.push(item.id)
        : (selectedAmenities[0] = item.id);
    }
    const amenitiesList = amenities?.filter(item => newUnit?.amenities?.includes(item?.id))?.map(item => item?.name?.split(" ")?.join("-"));
    setNewUnit(() => {
      let keywords = [];
      keywords?.push(newUnit?.unitNum);
      keywords?.push(newUnit?.facilityName?.split(" ")?.join("-"));
      keywords?.push(...amenitiesList);
      return {
        ...newUnit,
        amenities: selectedAmenities,
        selectedAmenities: amenitiesList,
        seoKeywords: keywords?.filter(item => item !== "" && item !== null && item !== undefined)?.join(",")
      };
    });
  };


  return (
    <div className='-mt-4 px-3 h-[480px] overflow-y-scroll'>
      <>
        {amenityListLoading ? (
          <InsideSpinner />
        ) : (
          <>
            {amenities?.length > 0 ? (
              <>
                <p className='mt-4 mb-1' style={Styles.headingTextWhite}>
                  Choose the Appropriate Amenities:
                </p>
                <div className='!h-80 overflow-y-scroll pl-2'>
                  {amenities?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index % 2 !== 0 ? null : (
                          <div className='w-1/2 float-left'>
                            <CheckBox
                              color={white}
                              checked={Boolean(
                                newUnit?.amenities?.find(
                                  (amenity) => amenity === item.id
                                )
                              )}
                              onChange={() => onAmenitySelect(item)}
                              label={camalize(item.name)}
                              amenities={amenities}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}

                  {amenities?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index % 2 === 0 ? null : (
                          <div key={index} className='w-1/2 float-left'>
                            <CheckBox
                              color={white}
                              checked={Boolean(
                                newUnit?.amenities?.find(
                                  (amenity) => amenity === item.id
                                )
                              )}
                              onChange={() => onAmenitySelect(item)}
                              label={camalize(item.name)}
                              amenities={amenities}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className='flex justify-center mt-[100px]'>
                <h4 className='mt-4 mb-1 text-xl text-white'>
                  <span>No Amenities Found!</span>
                </h4>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default Amenities;
