import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { Box } from '@mui/material';
// Local Imports
import { toolBar, fontSizeList } from '../../utils/utils';
import {
  gray,
  primaryColor,
  secondaryColor,
  yellow,
} from '../../utils/style/GlobalVariables';
import {
  createImageTag,
  createVideoTag,
  LinkPopover,
} from './editorcomponents';

// CSS
import './editor.css';
// Local Imports

const CustomEditor = forwardRef((props, ref) => {
  const {
    id,
    newPost,
    imageSize,
    setValue,
    setImageSizeError,
    setVideoSizeError,
    setIsShowBlock,
    imageWidth,
    imageHeight,
    setImageWidth,
    setImageHeight,
    editorHTML,
    letterCases,
    setLetterCases,
    setEditorHTML,
    videoSettings,
    setVideoSettings,
    setNewPostMedia,
    setDeletedIds,
    links,
    deleteBlogContent,
  } = props;
  // Editor States
  const [activeCommands, setActiveCommands] = useState({});
  // Refs
  const editorRef = useRef();
  // Editor States And Methods
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showLinkPopover, setShowLinkPopover] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [selectedRange, setSelectedRange] = useState(null);
  const [url, setUrl] = useState('');
  const [anchorTag, setAnchorTag] = useState('');
  const [popoverPositions, setPopoverPosition] = useState({ x: '', y: '' });
  const [isCapitilize, setIsCapitilize] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [fontSize, setFontSize] = useState('');
  const [totalImgVideoTags, setTotalImgVideoTags] = useState([]);
  const [imgVideoTagsToRemove, setImgVideoTagsToRemove] = useState([]);

  //************************************ Handle Manage Editor  Commands **********************************/ //
  if (editorRef.current?.innerHTML === '') {
    editorRef.current.innerHTML = editorHTML;
  } else if (deleteBlogContent && editorRef.current?.innerHTML !== '' && editorRef.current?.innerHTML !== null && editorRef.current?.innerHTML !== undefined) {
    editorRef.current.innerHTML = '';
  }

  const handleMangeCmd = async (e, item) => {
    e.preventDefault();
    const { exeCmd, absence } = item;
    // For Deselect Alignments
    if (exeCmd?.startsWith('justify')) {
      const alignmentCommands = [
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
      ];
      alignmentCommands.forEach((alignment) => {
        if (alignment !== exeCmd) {
          setActiveCommands((prevState) => ({
            ...prevState,
            [alignment]: false,
          }));
        }
      });
    }
    if (exeCmd === 'insertUnorderedList') {
      document.execCommand(exeCmd, false, absence);
      return;
    }
    const isActive = activeCommands[exeCmd];
    setActiveCommands((prevState) => ({
      ...prevState,
      [exeCmd]: !isActive,
    }));
    document.execCommand(exeCmd, false, absence);
  };

  const handleImageAndVdieoByCMD = (e, item) => {
    editorRef.current?.focus();
    const { exeCmd } = item;

    const isActive = activeCommands[exeCmd];
    setActiveCommands((prevState) => ({
      ...prevState,
      [exeCmd]: !isActive,
    }));
    // Insert Image
    if (exeCmd === 'insertImage') {
      createImageTag(
        editorRef,
        exeCmd,
        setIsShowBlock,
        setActiveCommands,
        setImageSizeError,
        setNewPostMedia,
        setEditorHTML,
      );
    }
    // Insert Video
    if (exeCmd === 'insertVideo') {
      createVideoTag(
        editorRef,
        exeCmd,
        setIsShowBlock,
        setActiveCommands,
        setVideoSizeError,
        setNewPostMedia
      );
    }
  };

  // ************************************** Handle Open CreateLink **************************************//
  const handleOpenLinkPopover = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    if (selectedText.length > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      setSelectedText(selection.toString());
      setSelectedRange(range);
      setShowLinkPopover(true);
      const tooltipX = rect.x + window.scrollX;
      const tooltipY = rect.y + window.scrollY + rect.height;
      setPopoverPosition({ x: tooltipX, y: tooltipY });
    }
  };

  const handleLinkAdded = (url) => {
    anchorTag.href = url;

    setEditorHTML(editorRef.current.innerHTML);
    setShowLinkPopover(false);
  };

  // ************************************** Handle Change Font Size **************************************//

  const handleOnChangeFontSize = (event) => {
    setFontSize(event.target.value);
    if (editorRef.current) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      if (selection.rangeCount) {
        var e = document.createElement('span');
        e.style = `font-size: ${event.target.value};`;
        e.innerHTML = selection.toString();
        range.deleteContents();
        range.insertNode(e);
      }
    }
  };
  // ************************************** Handle Typo Options **************************************//

  const handleTypoOption = (type) => {
    if (editorRef.current) {
      editorRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editorRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      if (type === 'underline') {
        setLetterCases((cases) => ({
          ...cases,
          underscore: !letterCases.underscore,
        }));
        document.execCommand('underline', false, null);
      }
      if (type === 'capitalize') {
        setIsCapitilize(!isCapitilize);
        setIsUpperCase(false);
        setIsLowerCase(false);
        setLetterCases((cases) => ({
          ...cases,
          upperCase: false,
          lowerCase: false,
          capitilize: !letterCases.capitilize,
        }));
        if (isCapitilize) {
          document.execCommand('removeFormat', true, null);
        } else {
          document.execCommand('formatblock', false, 'p');
        }
      }
      if (type === 'uppercase') {
        setIsUpperCase(!isUpperCase);
        setIsCapitilize(false);
        setLetterCases((cases) => ({
          ...cases,
          lowerCase: false,
          capitilize: false,
          upperCase: !letterCases.upperCase,
        }));
        if (isUpperCase) {
          console.log('in if');
          document.execCommand('removeFormat', true, null);
        } else {
          console.log('in els');
          document.execCommand('formatblock', false, 'p');
        }
      }
      if (type === 'lowercase') {
        setIsLowerCase(!isLowerCase);
        setIsCapitilize(false);
        setIsUpperCase(false);
        setLetterCases((cases) => ({
          ...cases,
          capitilize: false,
          upperCase: false,
          lowerCase: !letterCases.lowerCase,
        }));
        if (isLowerCase) {
          document.execCommand('removeFormat', true, null);
        } else {
          document.execCommand('formatblock', false, 'p');
        }
      }
    }
  };

  // useImperative
  useImperativeHandle(ref, () => ({
    handleTypoOption,
  }));

  // ************************************** Get Filtered Tags **************************************//

  const getTmgAndVideoTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const tags = [...doc.querySelectorAll('video, img')];
    return tags.map((tag) => tag.outerHTML);
  };

  const filteredVideoImgTags = (html) => {
    const currentTags = getTmgAndVideoTags(html);
    const removedTags = totalImgVideoTags.filter(
      (tag) => !currentTags.includes(tag)
    );
    setImgVideoTagsToRemove(() => {
      return removedTags;
    });
  };

  // UseEffect
  useEffect(() => {
    if (editorRef.current) {
      editorRef?.current?.addEventListener('click', (event) => {
        if (event.target.tagName === 'IMG') {
          setValue(1);
          setIsShowBlock(() => ({
            isTypoOptions: false,
            isVideoOptions: false,
            isImageOptions: true,
          }));
          const img = event.target;
          setSelectedImage(img);
          setImageWidth('');
          setImageHeight('');
        } else if (event.target.tagName === 'VIDEO') {
          setValue(1);
          const video = event.target;
          const { autoplay, loop, muted, controls, playsInline } = event.target;
          setVideoSettings({
            autoplay: autoplay,
            loop: loop,
            muted: muted,
            playbackControls: controls,
            playInline: playsInline,
          });
          setSelectedVideo(video);
          setIsShowBlock(() => ({
            isTypoOptions: false,
            isImageOptions: false,
            isVideoOptions: true,
          }));
        } else if (event.target.tagName === 'A') {
          const getTag = event.target;
          const url = getTag.getAttribute('href');
          setAnchorTag(getTag)
          setUrl(url);
          setShowLinkPopover(true);
          setPopoverPosition({ x: event.clientX, y: event.clientY });
        } else {
          setValue(1);
          setIsShowBlock(() => ({
            isTypoOptions: true,
            isImageOptions: false,
            isVideoOptions: false,
          }));
          setFontSize('');
        }
      });
      // Check Letter Cases
      if (editorRef.current) {
        editorRef.current?.addEventListener('keydown', (event) => {
          const selection = window.getSelection();
          if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const element = range.commonAncestorContainer.parentElement;
            if (element.tagName === 'P') {
              if (isCapitilize) {
                element.style.textTransform = 'capitalize';
              } else if (isUpperCase) {
                element.style.textTransform = 'uppercase';
              } else if (isLowerCase) {
                element.style.textTransform = 'lowercase';
              } else {
                element.style.textTransform = 'lowercase';
              }
            }
          } else {
            // Move the cursor to the parent <div> in the 'else' condition
            const divElement = container.closest('div');
            if (divElement) {
              const newRange = document.createRange();
              newRange.selectNodeContents(divElement);
              newRange.collapse(false);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
          if (event.key === 'Enter') {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const parentSpan = range.commonAncestorContainer.parentElement;
            if (parentSpan.tagName === 'SPAN') {
              const span = parentSpan;
              const textNode = document.createTextNode('\u200B');
              span.parentNode.insertBefore(textNode, span.nextSibling);
              const range = document.createRange();
              range.setStart(textNode, 1);
              range.collapse(true);
              const selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
              event.preventDefault();
            }
          }
        });
      }
    }
  }, [editorRef.current, isCapitilize, isLowerCase, isUpperCase]);

  // UseEffect For Change Run Time Image Width And Height
  useEffect(() => {
    if (selectedImage !== null) {
      selectedImage.style.width =
        imageSize?.value === 'default' ? `auto` : `${imageWidth}px`;
      selectedImage.style.height =
        imageSize?.value === 'default' ? `auto` : `${imageHeight}px`;
    }
  }, [imageWidth, imageHeight, imageSize]);

  // Update Video Controls For Every Video
  useEffect(() => {
    if (selectedVideo !== null) {
      selectedVideo.autoplay = videoSettings?.autoplay;
      selectedVideo.loop = videoSettings.loop;
      selectedVideo.muted = videoSettings.muted;
      selectedVideo.controls = videoSettings.playbackControls;
      selectedVideo.playInline = videoSettings.playInline;
    }
  }, [videoSettings, setVideoSettings]);

  // Update DIV content when Update
  useEffect(() => {
    if (id) {
      const newHTML = fnToSetURLs(editorHTML, newPost?.imagesForEdit);
      setTotalImgVideoTags(getTmgAndVideoTags(newHTML));
      editorRef.current.innerHTML = newHTML;
    }
  }, [id]);

  function fnToSetURLs(html, orignalURls) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const images = doc.querySelectorAll('img');
    const videos = doc.querySelectorAll('video');
    const imgURLs = orignalURls?.filter(
      (img) => img.featured !== 'featured' && !img?.is_video
    );
    const videoURLs = orignalURls?.filter((video) => video?.is_video);
    images.forEach((img, index) => {
      let url;
      url = imgURLs[index]?.url;
      img.src = url;
      img.id = imgURLs[index]?.id;
    });
    videos.forEach((video, index) => {
      let url;
      url = videoURLs[index]?.url;
      video.src = url;
      video.id = videoURLs[index]?.id;
      video.autoplay = videoURLs[index]?.autoplay;
      video.loop = videoURLs[index]?.loop;
      video.muted = videoURLs[index]?.muted;
      video.playsInline = videoURLs[index]?.play_inline;
      video.controls = videoURLs[index]?.playback_controls;
    });
    return doc.body.innerHTML;
  }

  // Handle To Get Ids In Edit
  const handleImgVideoTagsToRemove = useCallback(() => {
    if (imgVideoTagsToRemove.length > 0) {
      setDeletedIds((prev) => {
        const ids = imgVideoTagsToRemove?.map((tag) => {
          let tagHTML = new DOMParser()
            .parseFromString(tag, 'text/html')
            .querySelectorAll('img, video');
          let id = +tagHTML[0].id;
          if (!prev?.some((item) => item?.id === id)) {
            return newPost?.imagesForEdit?.find((img) => img?.id == id);
          } else {
            return null;
          }
        });
        const filterIds = ids?.filter((id) => id !== null && id !== undefined);
        return [...prev, ...filterIds];
      });
    }
  }, [imgVideoTagsToRemove, newPost]);

  useEffect(() => {
    handleImgVideoTagsToRemove();
  }, [imgVideoTagsToRemove]);

  useImperativeHandle(ref, () => ({
    updateLinks() {
      if (editorRef.current) {
        let updatedText = editorRef.current.innerHTML;
        updatedText = updatedText.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '$1');
        links.forEach((link) => {
          // Replace keyword with link
          const escapedKeyword = link.keyword.replace(
            /\b[.*+?^${}()|[\]\\]\b/g,
            '\\$&'
          )
          // Replace keyword that is not part of other word with link
          const regex = new RegExp(`\\b${escapedKeyword}\\b(?!([^<]+)?>)`, 'gi');
          updatedText = updatedText.replace(
            regex,
            (match) => `<a href="${link.link}" target="_blank">${match}</a>`
          );
        });
        editorRef.current.innerHTML = updatedText;
        setEditorHTML(updatedText);
      }
    }
  }));

  return (
    <Box className='w-full min-h-[300px] max-h-max  bg-[#1B1B23] rounded-lg mt-3 '>
      <Box
        display='flex'
        justifyContent='start'
        borderBottom={`2px solid ${primaryColor}`}
        className='sticky'
      >
        <select
          onChange={handleOnChangeFontSize}
          value={fontSize}
          className='font-["Inter"] focus:outline-none px-2 rounded-lg'
          style={{ backgroundColor: secondaryColor, color: gray }}
        >
          {fontSizeList.map((font, index) => (
            <option value={font.value} key={index}>
              {font.label}
            </option>
          ))}
        </select>
        {toolBar?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  borderRight:
                    item?.exeCmd === 'bold'
                      ? `2px solid ${primaryColor}`
                      : 'unset',
                  padding: '10px 5px',
                }}
              >
                <button
                  key={index}
                  onMouseDown={
                    item?.exeCmd === 'insertImage'
                      ? (e) => handleImageAndVdieoByCMD(e, item)
                      : item?.exeCmd === 'insertVideo'
                        ? (e) => handleImageAndVdieoByCMD(e, item)
                        : item?.exeCmd === 'createLink'
                          ? handleOpenLinkPopover
                          : (e) => handleMangeCmd(e, item)
                  }
                  className={` ${activeCommands[item.exeCmd]
                    ? 'bg-gray-300 rounded-md '
                    : 'unset rounded-none '
                    } `}
                >
                  {item?.icon}
                </button>
              </div>
            </React.Fragment>
          );
        })}
      </Box>

      <div
        ref={editorRef}
        className='contentEditableArea max-h-[445px] min-h-[240px] overflow-scroll mt-1 border-none outline-none px-[10px] py-[5px] bg-transparent text-white text-[16px] font-["Inter"]'
        id='contentEditableArea'
        contentEditable={true}
        role='textbox'
        onPaste={(event) => {
          event.preventDefault();
          const text = event.clipboardData.getData('text/plain');
          document.execCommand('insertText', false, text);
        }}
        onInput={(e) => {
          setEditorHTML(e.target.innerHTML);
          const updatedContent = e.target.innerHTML;
          filteredVideoImgTags(updatedContent);
          if (e.target.innerText === '<br>') {
            setActiveCommands({});
            setIsCapitilize(false);
            setIsLowerCase(false);
            setIsUpperCase(false);
          }
        }}
      />
      {showLinkPopover && (
        <LinkPopover
          onLinkAdded={handleLinkAdded}
          onClose={() => setShowLinkPopover(false)}
          position={popoverPositions}
          url={url}
          setUrl={setUrl}
        />
      )}
    </Box>
  );
});
// const BlogEditor = forwardRef(CustomEditor);
export default CustomEditor;