import { SearchBar } from '../../../../../components/SearchBar/SearchBar';
import { InsideSpinner } from '../../../../../components/Spinner/Spinner';
import UnitTable from '../../../../../components/Tables/Table';
import FilterListIcon from '@mui/icons-material/FilterList';
import { secondaryColor } from '../../../../../utils/style/GlobalVariables';
import { Styles } from '../../../../../utils/style/GlobalStyles';
import { MyPopper } from '../../../../../components/Filter/Filters';
import { Dropdown } from '../../../../../components/Dropdown';
import CloseIcon from '@mui/icons-material/Close';

const UnitPageUI = ({
  loading,
  selectionIds,
  setSelectionIds,
  unitColumnData,
  unitColumnExtensionsData,
  unitRowData,
  dataProviders,
  onChangeSearch,
  onUnitsSearch,
  onClear,
  searchText,
  // Filter Modal States
  clickAwayHandler,
  anchorEl,
  id,
  open,
  listOpen,
  filters,
  handleClick,
  handleClose,
  campusesList,
  handleClickForParent,
  onChangeFilter,
  onClearFilter,
}) => {
  const campuses = campusesList.map(({ id, name }) => {
    return { id: id, title: name, name, value: filters === name };
  });

  return (
    <div className='main-container px-6 pt-3'>
      <div className='flex flex-row justify-between align-items-center py-3 gap-5'>
        {/* Filter Popover */}
        <div
          style={{
            background: secondaryColor,
            height: 38,
            width: '100px',
            cursor: !searchText ? 'pointer' : 'not-allowed',
          }}
          onClick={!searchText ? handleClick : null}
          className='flex justify-center text-sm flex-row align-items-center rounded-lg pointer p-2'
        >
          <FilterListIcon className='mr-2' fontSize='small' color='secondary' />
          <p style={Styles.disableBtnText}>Filter</p>
        </div>

        <div className='flex-1'>
          <SearchBar
            disabled={selectionIds.length > 0 ? true : false}
            onSearch={() => onUnitsSearch()}
            onClear={() => onClear()}
            onChange={onChangeSearch}
            value={searchText}
          />
        </div>
      </div>

      {/* To Show Selected Campus */}
      {filters && (
        <div className='flex items-center gap-2 bg-[#CDA950] py-2 pl-2 pr-1 mt-1 rounded-lg w-fit'>
          <span style={Styles.mediumTextPrimary}>
            Campus:
          </span>

          <span style={Styles.smallTextPrimary}>{filters}</span>

          <span className='cursor-pointer' onClick={onClearFilter}>
            <CloseIcon
              className='ml-2 mb-[4px]'
              fontSize='inherit'
              color='primary'
            />
          </span>
        </div>
      )}

      {loading ? (
        <InsideSpinner />
      ) : (
        <UnitTable
          rows={unitRowData}
          columns={unitColumnData}
          tableColumnExtensions={unitColumnExtensionsData}
          dataProviders={dataProviders}
          selectionIds={selectionIds}
          setSelectionIds={setSelectionIds}
          unitsSelectedCampus={filters}
        />
      )}
      {/* Filter Popover */}
      {anchorEl && (
        <MyPopper
          clickAwayHandler={clickAwayHandler}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onClear={onClearFilter}
        >
          <>
            <Dropdown
              onChangeFilter={onChangeFilter}
              options={campuses}
              title='Campus'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='checkbox'
            />
          </>
        </MyPopper>
      )}
    </div>
  );
};

export default UnitPageUI;
