import { Box } from '@mui/material';
import { yellow } from '../../../utils/style/GlobalVariables';

const BlogPreviewPage = ({ data }) => {
  const content = data?.blog?.toString();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return (
    <div>
      <div className='mt-8'>
        <p className='text-center text-white text-2xl sm:text-4xl'>
          Blog Preview Page
        </p>
        <p className='text-center text-gray-400 text-sm mt-10'>
          {formattedDate}
        </p>
        <h1
          className='text-white text-center md:px-0 px-5 text-3xl md:text-4xl lg:text-5xl'
        >
          {data?.title}
        </h1>
        <div className='mt-5 flex justify-center gap-3 flex-wrap px-5 md:px-0'>
          {data?.tags?.map((tag, index) => {
            return (
              <span
                key={`${index}`}
                className={`px-2.5 py-1 flex justify-center items-center text-sm rounded-full text-[${yellow}] border-2 border-[${yellow}]`}
              >
                {tag}
              </span>
            );
          })}
        </div>
      </div>
      <div
        className='my-8 flex items-center rounded-3xl xl:w-[1200px] w-[90vw] mx-auto overflow-hidden lg:h-[500px] md:h-[400px]'
      >
        <img
          className='object-cover object-center rounded-3xl w-full'
          src={data?.featureImage}
          alt=''
        ></img>
      </div>
      <section
        className='text-white body-font'
        style={{ paddingBottom: '50px' }}
      >
        <div className='mx-auto'>
          <div className='mx-auto px-4 md:px-10 lg:w-4/5'>
            <Box
              component='div'
              dangerouslySetInnerHTML={{ __html: content }}
              sx={{
                img: {
                  borderRadius: '10px',
                },
                '& .remove-img-btn': {
                  display: 'none !important',
                },
                '& .videoCloseButton': {
                  display: 'none !important',
                },
                a: {
                  color: yellow,
                  cursor: 'pointer',
                },
                ul: {
                  paddingLeft: '40px',
                  marginBlock: '15px',
                },
                li: {
                  listStyle: 'disc',
                },
                h1: {
                  fontSize: '26px',
                  fontWeight: 'bold',
                  marginBlock: '20px',
                },
                h2: {
                  fontSize: '26px',
                  fontWeight: 'bold',
                  marginBlock: '20px'
                },
                h3: {
                  fontSize: '26px',
                  marginBlock: '16px'
                },
                h4: {
                  fontSize: '20px',
                  marginBlock: '14px'
                },
                h5: {
                  fontSize: '16px',
                  marginBlock: '12px'
                },
                h6: {
                  fontSize: '12px',
                  marginBlock: '12px'
                },
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPreviewPage;
